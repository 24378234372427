body {
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

h1, h2, h3, h4, h5 {
  line-height: 1;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

:root {
  --brand: #04547d;
}

.text-primary {
  color: var(--brand) !important;
}

.btn-primary {
  background-color: var(--brand) !important;
  border-color: var(--brand) !important;
}

a {
  color: var(--brand);
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}